import { useState, useEffect } from "react";
import Markdown from 'marked-react';
import Lowlight from 'react-lowlight';
import 'highlight.js/styles/monokai.css'
import hljs from "highlight.js";
import "react-lowlight/common";

import "./index.css"
import prompt from "./prompt"

const md5 = require('md5');
const maxWidth = "640px";
const transition = "transition-all duration-100 ease-in-out"
const transitionSlow = "transition-all duration-300 ease-in-out"
hljs.initHighlighting()

const renderer = {
  code(snippet, lang) {
    return <div class="m-2">
      <Lowlight key={this.elementId} language={lang} value={snippet} />
    </div>
  },
};

const Spinner = (props) => {
  return(
    <svg aria-hidden="true" class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#0066bb]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
  )
}

const ChatTurn = (props) => {
  if (props.turn.role === "System") {
    return;
  } else {
    const format1 = props.turn.role==="Assistant" ? "  text-[11px] font-light": " text-[14px] bg-[#0066bb25]"
    const format2 = props.turnI===1 ? " rounded-tr-xl": ""
    return <div class={`p-2${format1}${format2}`}>
      {props.saveFunc && <div class="h-0 absolute flex justify-end" style={{width: maxWidth}}><div class={`h-4 w-4 flex items-center justify-center rounded-full hover:cursor-pointer ${props.isSaved ? 'text-[14px] -rotate-45': '-mr-8 text-[7px] rotate-45'} ${transitionSlow}`} onClick={() => props.saveFunc()}>📎
</div></div>}
      {props.turn.loading ? <Spinner/> :<Markdown breaks={true} gfm={true} value={props.turn.content} renderer={renderer}/>}
    </div>
  }
}

const Chat = (props) => {
  return(
    <div class="border border-px border-gray-300 shadow-sm rounded-tr-xl">
      {props.chatObject.map((turn, i) => <ChatTurn turn={turn} turnI={i} isSaved={props.savedMessages.includes(i)} saveFunc={turn.role === "Human" && i + 1 < props.chatObject.length ? () => props.saveFunc(i): null}/>)}
      {props.responding && <ChatTurn turn={{role: "Assistant", loading: true}} turnI={props.chatObject.length}/>}
    </div>
  )
}

const Input = (props) => {
  const [inputState, setInputState] = useState("")
  const outlineFormatting = props.responding ? "": "focus:outline-[1px] focus:outline-black"
  return(
    <div class="flex flex-col mx-[1px] mt-8">
      <textarea class={`text-xs rounded-tr-xl p-2 h-auto overflow-hidden resize-none w-full outline hover:outline-t-none outline-[1px] outline-gray-200 ${outlineFormatting} ${transition}`}
        tabIndex={1}
        readOnly={props.responding}
        name="input"
        rows={1}
        value={inputState}
        onChange={(e) => setInputState(e.target.value)}
      />
      <div class="flex flex-row justify-end">
        <button type="button" class={`py-2 px-3 my-2 w-auto bg-[#0066bb] hover:bg-[#003562] focus:bg-[#003562] text-xs hover:cursor-pointer outline-none text-white ${transition}`} onClick={(e) => {
          props.setChatRawText(props.chatRawText + "\nHuman: " + inputState)
          props.setResponding(true)
          setInputState('');
          document.querySelector(`[tabindex="${1}"]`).focus();
        }}
        tabIndex={2}>
          Submit
        </button>
      </div>
    </div>
  )
}

const StaticInput = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  return(
    <div className="text-xs text-gray-400 underline flex-1 mr-4">
      <div className="mb-2 hover:cursor-pointer" onClick={() => setIsCollapsed(!isCollapsed)}>{props.title}</div>
      <textarea rows={props.rows || 3} className={`text-[11px] p-2 overflow-hidden resize-none w-full outline hover:outline-t-none outline-[1px] outline-gray-200 focus:outline-[1px] focus:outline-black ${transition} ${isCollapsed ? 'invisible': ''}`} name={props.title} value={props.value} onChange={(e) => props.setValue(e.target.value)}/>
    </div>
  )
}

const Header = () => {
  return(
    <div class="h-14 bg-gray-100 bg-opacity-90 backdrop-blur-sm top-0 fixed w-screen z-10 border border-b-px">
      <div class="h-full mx-auto flex items-center" style={{maxWidth: maxWidth}}>
        <div class="flex flex-col text-sm font-black mb-1">
          <div class="flex flex-row">
            <div class="w-4 h-4 text-center">S</div>
            <div class="w-4 h-4 text-center">P</div>
          </div>
          <div class="flex flex-row">
            <div class="w-4 h-4 text-center">I</div>
            <div class="w-4 h-4 text-center">N</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const rawTextToObject = (rawText) => {
  const lines = rawText.split("\n");
  var chatObject = []
  for (var line_i = 0; line_i < lines.length; line_i++) {
    const role = lines[line_i].split(":")[0].trim();
    if (role === "Human" || role === "Assistant" || role === "System") {
      var content = lines[line_i].slice(role.length+1).trim()
      const useRole = line_i == 0 && role == "Human" ? "System": role
      chatObject.push({role: useRole, content})
    } else {
      chatObject[chatObject.length-1].content += "\n" + lines[line_i]      
    }
  }
  return chatObject
}

function App() {
  const [prevTurnChatHash, setPrevTurnChatHash] = useState()
  const [dummy, setDummy] = useState(1);
  const [responding, setResponding] = useState(false);
  const [proxyUrl, setProxyUrl] = useState(localStorage.getItem('proxyUrl'));
  const [apiKey, setApiKey] = useState(localStorage.getItem('apiKey'))
  const [basePrompt, setBasePrompt] = useState(localStorage.getItem('basePrompt') || prompt)
  const [chatRawText, setChatRawText] = useState(localStorage.getItem('basePrompt') || prompt);
  const [savedMessages, setSavedMessages] = useState([])

  useEffect(() => {
    localStorage.setItem('apiKey', apiKey)
  }, [apiKey])

  useEffect(() => {
    localStorage.setItem('proxyUrl', proxyUrl)
  }, [proxyUrl])

  useEffect(() => {
    localStorage.setItem('basePrompt', basePrompt)
  }, [basePrompt])

  useEffect(() => {
    const localChatObject = rawTextToObject(chatRawText)
    const chatHash = md5(chatRawText);
    const latestTurn = localChatObject[localChatObject.length - 1]
    const longPoll = async () => {
      const response = await fetch(proxyUrl + "/longpoll", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({hash: prevTurnChatHash})
      })
      const responseData = await response.json();
      if (responseData.raw && responseData.raw.length >= chatRawText.length) {
        if (chatRawText !== responseData.raw) {
          setChatRawText(responseData.raw)
        }
      }
      console.log(responseData)
      if (responseData.live) {
        setDummy(dummy+1)
      } else if (responseData.finished) {
        setResponding(false)
      }
    }
    if (prevTurnChatHash) {
      longPoll()
    }
    const runPrompt = async () => {
      if (latestTurn.role === 'Human' && prevTurnChatHash !== chatHash) {
        await fetch(proxyUrl + "/prompt", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({hash: chatHash, raw: chatRawText, isNew: localChatObject.length === 1, apiKey: apiKey})
        })
        setPrevTurnChatHash(chatHash)
      }
    }
    runPrompt()
  }, [dummy, proxyUrl, chatRawText, prevTurnChatHash, apiKey])
  
  const chatObject = rawTextToObject(chatRawText)
  const saveFunc = async (startI) => {
    const q = chatObject[startI]
    const a = chatObject[startI + 1]
    var operation;
    if (savedMessages.includes(startI)) {
      setSavedMessages(savedMessages.filter(i => i !== startI))
      operation = "remove"
    } else {
      setSavedMessages([...savedMessages, startI])
      operation = "add"
    }
    await fetch(proxyUrl + "/add_good_pair", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({q: q.content, a: a.content, operation})
    })
  }

  return (
    <div>
      <Header/>
      <div class="mx-auto mt-32 mb-64 text-[#1b1b1b]" style={{width: maxWidth}}>
        <Chat chatRawText={chatRawText} responding={responding} chatObject={chatObject} saveFunc={saveFunc} savedMessages={savedMessages}/>
        <Input chatRawText={chatRawText} setChatRawText={setChatRawText} responding={responding} setResponding={setResponding}/>
        <div class="flex flex-row mt-48">
          <StaticInput title="Proxy URL" value={proxyUrl} setValue={setProxyUrl}/>
          <StaticInput title="API Key" value={apiKey} setValue={setApiKey}/>
        </div>
        <div class="flex">
          <StaticInput title="Base Prompt" rows={16} value={basePrompt} setValue={setBasePrompt}/>
        </div>
      </div>
    </div>
  );
}

export default App;
